/*

──────────────────────────────────────────
──────────────────────────────────────────
IS
──────────────────────────────────────────
──────────────────────────────────────────

isNodeList( object );
isHTMLCollection( object );
isTouchDevice;

*/

//import is from 'is_js';

export const isNodeList = (v) => {
  return v instanceof NodeList;
}

export const isHTMLCollection = (v) => {
  return v instanceof HTMLCollection;
}

// is a given value window?
export const isWindow = (v) => v != null && typeof v === "object" && "setInterval" in v;

//export const isTouchDevice = is.ios() || is.android() || is.windowsPhone() || is.windowsTablet();
export const isTouchDevice = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export default {
  isNodeList,
  isHTMLCollection,
  isWindow,
  isTouchDevice,
}
