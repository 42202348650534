import { isWindow } from "./is";

const freeSelf = isWindow(typeof self == "object" && self) && self;
const navigator = freeSelf && freeSelf.navigator;
const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase();
const vendor = (navigator && navigator.vendor || '').toLowerCase();

// build a 'comparator' object for various comparison checks
const comparator = {
  "<": function (a, b) {
    return a < b;
  },
  "<=": function (a, b) {
    return a <= b;
  },
  ">": function (a, b) {
    return a > b;
  },
  ">=": function (a, b) {
    return a >= b;
  }
};

// helper function which compares a version to a range
const compareVersion = (version, range) => {
  const string = range + "";
  const n = +(string.match(/\d+/) || NaN);
  const op = string.match(/^[<>]=?|/)[0];

  return comparator[op] ? comparator[op](version, n) : version == n || n !== n;
};

export const chrome = (range) => {
  // eslint-disable-next-line no-undef
  const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
  return match !== null && !opera() && compareVersion(match[1], range);
};
export const firefox = (range) => {
  const match = userAgent.match(/(?:firefox|fxios)\/(\d+)/);
  return match !== null && compareVersion(match[1], range);
};
export const safari = (range) => {
  const match = userAgent.match(/version\/(\d+).+?safari/);
  return match !== null && compareVersion(match[1], range);
};
export const opera = (range) => {
  const match = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
  return match !== null && compareVersion(match[1], range);
};

export default {
  chrome,
  firefox,
  safari,
  opera,
};
