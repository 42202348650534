import anime from 'animejs';

import { query } from '../pwup/dom/query';

export const SELECTOR = '[data-transition]';
export const HIDDEN_CLASSNAME = '--disabled';

class Transition {
  constructor(el) {
    this.el = el;
    this.background = query('.transition__bg', this.el);
    this.logo = query('.transition__logo', this.el);
    this.logotype = [ query('.transition__logo__m', this.logo), query('.transition__logo__a', this.logo), query('.transition__logo__c', this.logo) ];

    this.appear = this.enter.bind(this);
  }

  static init() {
    return new Transition( query(SELECTOR) );
  }

  hide() { this.el.classList.add(HIDDEN_CLASSNAME); }
  show() { this.el.classList.remove(HIDDEN_CLASSNAME); }

  leave() {
    const tl = anime.timeline();
          tl.add({
            targets: this.background,
            translateY: ['100%', '0%'],
            duration: 650,
            easing: 'easeInOutExpo',
          });
          tl.add({
            targets: this.logotype,
            opacity: {
              value: [0, 1],
              duration: 250,
              easing: 'linear',
            },
            translateY: [40, 0],
            duration: 850,
            easing: 'easeOutCubic',
            delay: anime.stagger(50),
          }, 450);

    return tl.finished;
  }
  enter() {
    const tl = anime.timeline();
          tl.add({
            targets: this.logotype,
            opacity: {
              value: [1, 0],
              duration: 250,
              easing: 'linear',
              delay: anime.stagger(30, {start: 400}),
            },
            translateY: [0, -40],
            duration: 650,
            easing: 'easeInCubic',
            delay: anime.stagger(30),
          }, 100);
          tl.add({
            targets: this.background,
            translateY: ['0%', '-100%'],
            duration: 1250,
            easing: 'easeInOutExpo',
          }, 400);

    return tl.finished;
  }
}

export default Transition;
