import { on } from '../core/listener';
import win from '../core/window';
import DOM from '../dom/DOM';
import ResizeOrientation from '../events/resizeOrientation';


const MobileViewportUnit = (() => {

  let updateInitialViewportHeight = true;

  //const onResize = () => { DOM.html.style.setProperty('--vh', `${win.height / win.devicePixelRatio * 0.01}px`); };
  const onResize = () => {
    DOM.html.style.setProperty('--vh', `${win.height * 0.01}px`);

    if( updateInitialViewportHeight === true ) {
      updateInitialViewportHeight = false;
      DOM.html.style.setProperty('--ivh', `${win.height * 0.01}px`);
    }
  };
  const onOrientation = () => { updateInitialViewportHeight = true; };

  const ctx = {
    init: () => {
      const ro = new ResizeOrientation(onResize);
            ro.run();
            ro.on();

      on(window, 'orientationchange', onOrientation);
    },
  };

  return ctx;

})();

export default MobileViewportUnit;
