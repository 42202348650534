import { chrome } from '@pwup/core/browser';
import win from '@pwup/core/window';
import ResizeOrientation from '@pwup/events/resizeOrientation';

import { BREAKPOINT_LG, BREAKPOINT_XL } from './breakpoints';

export const IS_PRODUCTION = process.env.NODE_ENV !== "development";
export const FABRIC_UDID_DATA_ATTR = 'fabricId';
export const FAVORITES_SELECTOR = '[data-favorites]';
export const PRODUCT_CAROUSEL_SELECTOR = '.product-single__carrousel';
export const PRODUCT_CAROUSEL_ARROWS_SELECTOR = '.product-single__imagesArrows';
export const PRODUCT_ARROW_LEFT_SELECTOR = '.product-single__imagesArrowLeft';
export const PRODUCT_ARROW_RIGHT_SELECTOR = '.product-single__imagesArrowRight';
export const IGNORE_HEADER_TOGGLE = chrome('<102') ? true : false;

export const {
  IS_MOBILE,
  IS_DESKTOP,
} = (() => {
  let is_mobile = false;
  let is_desktop = false;

  const onResize = () => {
    const w = win.width;

    is_mobile = w < BREAKPOINT_LG;
    is_desktop = w >= BREAKPOINT_XL;
  };

  const ro = new ResizeOrientation(onResize);
        ro.run();
        ro.on();

  return {
    IS_MOBILE: () => { return is_mobile; },
    IS_DESKTOP: () => { return is_desktop; },
  };
})();
