import store from 'store2';

const NAMESPACE = 'maisonannecarminati';
const STORE = store.namespace(NAMESPACE);

class Store {
  read(name, value) { return STORE ? STORE.get(name, value) : value; }
  write(name, value) { if( STORE ) STORE.set(name, value); }

  // getter - setter
  get exists() { return STORE; }
}

const SINGLETON = new Store();

export default SINGLETON;
