(function hello() {
  if( !window.console ) return;

  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('chrome') > -1 || ua.indexOf('firefox') > -1) {
    let args = [
      '%c %c  Code by Dominic Mercier  %c %c  https://dominic-mercier.com/  %c ',
      'background: #88c61e; padding:5px 0; margin:3px 0 10px 0;',
      'background: #222222; color: #ffffff; padding:5px 0; margin:3px 0 10px 0;',
      'background: #88c61e; padding:5px 0; margin:3px 0 10px 0;',
      'background: #222222; color: #ffffff; padding:5px 0; margin:3px 0 10px 0;',
      'background: #88c61e; padding:5px 0; margin:3px 0 10px 0;',
    ];

    window.console.log.apply(console, args);
  }
  else window.console.log('Code by Dominic Mercier - https://dominic-mercier.com/');
})();
